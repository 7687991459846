/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import { MapPin } from 'react-feather'

let mapkey = 'AIzaSyA5bfnlIrm89weVPEOJJsC6S5j-NmDs5TA'
if (process.env.GATSBYMAPKEY) {
  mapkey = process.env.GATSBYMAPKEY
}

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 53.095913057429854,
      lng: -1.2711394897585238
    },
    zoom: 14
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: mapkey }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <Marker
            lat={53.095913057429854}
            lng={-1.3904655886501454}
            text="SL Walters"
          />
        </GoogleMapReact>
      </div>
    )
  }
}

export default GoogleMap

const Marker = () => {
  return (
    <div style={{ color: 'red' }}>
      <MapPin />
    </div>
  )
}
