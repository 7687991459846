/* eslint-disable react/prop-types */
import React from 'react'
import { MapPin, Smartphone, Phone, Mail } from 'react-feather'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import FormSimpleAjax from '../components/FormSimpleAjax'
import Content from '../components/Content'
import GoogleMap from '../components/GoogleMap'
import Layout from '../components/Layout'
import '../components/ContactPage.css'

// Export Template for use in CMS preview
const ContactPageTemplate = ({ data }) => {
  const {
    body,
    title,
    subTitle,
    featuredImage,
    address,
    phone,
    mobile,
    email,
    location
  } = data.directus.contactPage
  return (
    <main className="Contact">
      <PageHeader
        title={title}
        subtitle={subTitle}
        backgroundImage={featuredImage}
      />
      <section className="section Contact--Section1">
        <div className="container Contact--Section1--Container">
          <div>
            <Content body={body} />
            <div className="Contact--Details">
              {address && (
                <a
                  className="Contact--Details--Item"
                  href={`https://www.google.co.uk/maps/search/${encodeURI(
                    address
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MapPin /> {address}
                </a>
              )}
              {phone && (
                <a className="Contact--Details--Item" href={`tel:${phone}`}>
                  <Phone /> {phone}
                </a>
              )}
              {mobile && (
                <a className="Contact--Details--Item" href={`tel:${mobile}`}>
                  <Smartphone /> {mobile}
                </a>
              )}
              {email && (
                <a className="Contact--Details--Item" href={`mailto:${email}`}>
                  <Mail /> {email}
                </a>
              )}
            </div>
          </div>

          <div>
            <FormSimpleAjax name="Simple Form Ajax" />
          </div>
        </div>
      </section>

      <GoogleMap locations={location} />
    </main>
  )
}

const ContactPage = ({ data }) => (
  <Layout meta={false}>
    <ContactPageTemplate data={data} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query contactPage {
    directus {
      contactPage {
        title
        subTitle
        featuredImage {
          id
        }
        body
        address
        phone
        mobile
        email
        location
      }
    }
  }
`
